



































































/* eslint-disable */
import {useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, ref} from '@vue/composition-api'

import DatepickerRecord from '@/components/DatepickerRecord.vue'
import UploadRecord from '@/components/UploadRecord.vue'
import moment from 'moment'

export default defineComponent({
  name: 'step-seven',
  components: {
    DatepickerRecord,
    UploadRecord,
  },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const {user, chrome} = useState(['user', 'chrome'])
    const {i18n, i18nSuivi} = useState('my', ['i18n', 'i18nSuivi'])
    const date = ref('')
    const SEVEN = ref('7')
    const SEVENTYONE = ref('71')
    const SEVENTYTWO = ref('72')
    const SEVENTYTHREE = ref('73')
    const SEVENTYFOUR = ref('74')

    const {currentLang} = useGetters(['currentLang'])

    if (_props.parentData.codeStatut === '73') {
      for (let i in _props.parentData.liste) {
        if (_props.parentData.liste[i].codeInfo === '100_1') {
          date.value = moment(_props.parentData.liste[i].valeurInfo)
              .locale(currentLang.value)
              .format('DD MMMM YYYY')
        }
      }
    }
    const processDate = (text: string) => {
      let html = text.replace('%date', '<span style="color:#ff4b00;">' + date.value + '</span>')
      return html
    }
    const processMail = (text: string) => {
      let html = ''
      if (_props.parentData.typeFluide === 'Elec') {
        html = text.replace('%mail', 'racc.planning.elec@resa.be')
        html = html.replace('%mail', '<span style="color:#ff4b00;">racc.planning.elec@resa.be</span>')
      } else {
        html = text.replace('%mail', 'racc.planning.gaz@resa.be')
        html = html.replace('%mail', '<span style="color:#ff4b00;">racc.planning.gaz@resa.be</span>')
      }
      return html
    }
    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-seven')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })
    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      date,
      i18nSuivi,
      SEVEN,
      SEVENTYONE,
      SEVENTYTWO,
      SEVENTYTHREE,
      SEVENTYFOUR,
      processDate,
      processMail,
      currentLang,
    }
  },
})
